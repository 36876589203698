const swiperConfig = {
	speed: 400,
	spaceBetween: 20,
	slidesPerView: 1.1,
	slidesOffsetAfter: 0,
	updateOnWindowResize: true,
	slideToClickedSlide: true,
	centeredSlidesBounds: true,
	centeredSlides: true,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	breakpoints: {
		560: {
			centeredSlidesBounds: false,
			centeredSlides: false,
		},
		770: {
			slidesPerView: 2,
			centeredSlidesBounds: false,
			centeredSlides: false,
		},
		1040: {
			slidesPerView: 2,
			centeredSlidesBounds: false,
			centeredSlides: false,
		},
		1200: {
			slidesPerView: 2.25,
			centeredSlidesBounds: false,
			centeredSlides: false,
		},
		1400: {
			slidesPerView: 3.25,
			centeredSlidesBounds: false,
			centeredSlides: false,
		}
	}
}

const hotshotsSwiperConfig = {
	...swiperConfig,
	slidesPerView: 1.5,
	centeredSlidesBounds: false,
	centeredSlides: false,
	breakpoints: {
		770: {
			slidesPerView: 2.5,
			slideToClickedSlide: true,
		},
		992: {
			slidesPerView: 3.5,
			slideToClickedSlide: false
		},
		1200: {
			slidesPerView: 4.2,
			slideToClickedSlide: false
		},
		1400: {
			slidesPerView: 5.2,
			slideToClickedSlide: false
		}
	},
	on: {
		click() {
			const popoverElement = this.clickedSlide.querySelector(".swiper-slide--popover");
			this.clickedSlide.style["z-index"] = "3";
			if (popoverElement) {
				popoverElement.style.display = 'grid';
				setTimeout(() => {
					popoverElement.classList.add('visible');
				}, 10);
			}
			const nextSlide = this.clickedSlide.nextElementSibling;
			const previousSlide = this.clickedSlide.previousElementSibling;
			if (nextSlide) {
				const nextPopover = nextSlide.querySelector(".swiper-slide--popover");
				nextSlide.style["z-index"] = "-1";
				if (nextPopover && nextPopover.classList.contains('visible')) {
					nextPopover.classList.remove('visible');
					setTimeout(() => {
						nextPopover.style.display = 'none';
					}, 450);
				}
			}

			if (previousSlide) {
				const previousPopover = previousSlide.querySelector(".swiper-slide--popover");
				previousSlide.style["z-index"] = "-1";
				if (previousPopover && previousPopover.classList.contains('visible')) {
					previousPopover.classList.remove('visible');
					setTimeout(() => {
						previousPopover.style.display = 'none';
					}, 450);
				}
			}
		}
	}
}

const closePopoverButtons = document.querySelectorAll(".close-popover");
closePopoverButtons.forEach(button => {
	button.addEventListener('click', (event) => {
		if (button.parentElement.parentElement.tagName === "OL") {
			const popover = event.currentTarget.parentElement;
			if (popover && popover.classList.contains('show')) {
				popover.classList.remove('show');
			}

		} else {
			const popover = event.currentTarget.parentElement;
			popover.parentElement.style["z-index"] = "-1";
			if (popover && popover.classList.contains('visible')) {
				popover.classList.remove('visible');
				setTimeout(() => {
					popover.style.display = 'none';
				}, 350);
			}
		}
	})
})

document.addEventListener('click', (event) => {
	const slides = document.querySelectorAll('.swiper-slide');
	let clickedInsideSlide = false;

	slides.forEach(slide => {
		if (slide.contains(event.target)) {
			clickedInsideSlide = true;
		}
	});
	const isNavButtonClicked = event.target.closest('.swiper-button-next, .swiper-button-prev');

	if (!clickedInsideSlide && !isNavButtonClicked) {
		slides.forEach(slide => {
			const popoverElement = slide.querySelector(".swiper-slide--popover");
			if (popoverElement && popoverElement.classList.contains('visible')) {
				popoverElement.classList.remove('visible');
				setTimeout(() => {
					popoverElement.style.display = 'none';
				}, 450);
				slide.style["z-index"] = "0";
			}
		});
	}
});

const swiper1 = new Swiper('.swiper-1', swiperConfig)
const swiper2 = new Swiper('.swiper-2', swiperConfig)
const swiper3 = new Swiper('.swiper-3', swiperConfig)
const swiper4 = new Swiper('.swiper-4', swiperConfig)
const swiper5 = new Swiper('.swiper-5', hotshotsSwiperConfig)
const swiper6 = new Swiper('.swiper-6', hotshotsSwiperConfig)
