import { secondsToTime } from "../server/components/utils"
import { h, render, Fragment } from 'preact'
import moment from 'moment'
import { T100Logo } from "../server/components/t100Logo"

let formRankings = document.getElementById('form-rankings')

if (formRankings) {
	let selects = Array.from(formRankings.getElementsByTagName("select"))
	selects.forEach(function (element, index) {
		element.addEventListener("change", (e) => {
			let btn = formRankings.querySelector("button[type=submit]")
			if (btn) btn.click()
		})
	});

	let radios = formRankings.querySelectorAll('input[type=radio]')
	radios.forEach(function (element, index) {
		element.addEventListener("change", (e) => {
			let btn = formRankings.querySelector("button[type=submit]")
			if (btn) btn.click()
		})
	})
}

const truncateMobile = document.querySelectorAll('.truncate-mobile')

if (window.innerWidth <= 768) {
	truncateMobile.forEach(el => {
		el.textContent = el.textContent.charAt(0) + "."
	})
} else {
	truncateMobile.forEach(el => {
		el.textContent = el.getAttribute('data-original-content')
	})
}

window.addEventListener('resize', () => {
	if (window.innerWidth <= 768) {
		truncateMobile.forEach(el => {
			el.textContent = el.textContent.charAt(0) + "."
		})
	} else {
		truncateMobile.forEach(el => {
			el.textContent = el.getAttribute('data-original-content')
		})
	}
})

let rankingsFilter = document.getElementById("form-rankings")

if (rankingsFilter) {
	rankingsFilter.addEventListener('submit', (e) => {
		e.preventDefault()

		var kvpairs = []
		for (let e of rankingsFilter.elements) {
			if (e.type == "radio" && !e.checked) continue
			if (e.value == "" || e.name == "") continue
			kvpairs.push(encodeURIComponent(e.name) + "=" + encodeURIComponent(e.value))
		}

		var queryString = kvpairs.join("&")
		location = location.origin + location.pathname + (queryString != "" ? "?" + queryString : "")
	})
}

const t100WinnerDropdowns = document.querySelectorAll(".pto-world-rankings--t100 .t100-winner")
if (t100WinnerDropdowns) {
	for (const t100WinnerDropdown of t100WinnerDropdowns) {
		const control = t100WinnerDropdown.querySelector(".fa-caret-square-down")
		control.addEventListener("click", function () {
			t100WinnerDropdown.querySelector(".t100-winner__results").classList.toggle("show")
		})
	}
}

let expandDetails = document.getElementsByClassName("expand-details")
if (expandDetails) {
	for (let expand of expandDetails) {
		expand.addEventListener("click", function (e) {
			let id = e.target.dataset.id || e.target.parentNode.dataset.id
			let setid = e.target.dataset.setid || e.target.parentNode.dataset.setid
			let discipline = e.target.dataset.discipline || e.target.parentNode.dataset.discipline
			let division = e.target.dataset.division || e.target.parentNode.dataset.division
			let seasonId = e.target.dataset.seasonid || e.target.parentNode.dataset.seasonid
			let athleteId = e.target.dataset.athleteid || e.target.parentNode.dataset.athleteid
			let disciplineIndex = 3
			let disciplineName = "overall"

			if (discipline == "swim") {
				disciplineIndex = 0
				disciplineName = "swim"
			}
			else if (discipline == "bike") {
				disciplineIndex = 1
				disciplineName = "bike"
			}
			else if (discipline == "run") {
				disciplineIndex = 2
				disciplineName = "run"
			}

			division = division == "MPRO" ? "men" : "women"

			let domDetails = document.getElementById('details-' + id)
			const isT100Rankings = domDetails.classList.contains('t100-rankings') ? true : false
			if (!domDetails.__expanded) {
				fetch("/api/series")
					.then(data => data.json())
					.then(data => {
						let series = data.series

						if (isT100Rankings) {
							fetch(`/t100/ranking/${seasonId}/${athleteId}/details`)
								.then(data => data.json())
								.then(data => {
									const details = data.results
									const { season } = data

									if (!details) {
										return
									}

									domDetails.__expanded = true

									render(
										<div className='rank-details' key={ 'details_' + id }>
											<div className='rank-row title'>
												<img src='../../assets/t100-logo-white.svg' alt='t100 logo' />
												<div>{ season.name } Races</div>
											</div>
											<div className='legend'>
												<span>Eligible results counting towards series total</span>
											</div>
											<div className='rank-row head'>
												<div className='date'>Date</div>
												<div className='race'>Race</div>
												<div className='position'>#</div>
												<div className='time'>Time</div>
												<div className='points'>
													<img src='../../assets/t100-logo-white.svg' alt='t100 logo' />
												</div>
											</div>
											{ details.map(x => {
												const isRaceObject = x.race !== undefined
												const isSeriesId = isRaceObject ? x.race?.series_id !== undefined : x.series_id !== undefined
												const alias = isSeriesId ? series.filter(s => s.id === x.series_id)[0].alias : (series.filter(s => s.name == x.serie)[0]?.alias || x.alias)

												return (
													<Fragment>
														<div className={ `rank-row ${x?.is_participant === false ? 'rank-row--dnp' : ''} ${(x?.is_qualifying_score === true || x?.is_t100_grand_final === 1) ? 'rank-row--qualifying' : ''}` }>
															<div className='date'>{ moment.utc(x.date).format("DD MMM") }</div>
															<a className='race' href={ '/race/' + (alias) + '/' + x.race_year + '/results' }>{ x.name.replace(/^\d{4}\s*/, '') }</a>
															<div className='position'>{ (x.rank == '99999' ? 'DNF' : x.rank) || <i className='fas fa-user-slash'></i> }</div>
															<div className='time'>{ x[disciplineName] ? secondsToTime(x[disciplineName]) : '-' }</div>
															<div className='points red'>
																{ x.t100_points ? x.t100_points.toFixed() : "-" }
															</div>
														</div>
														{ (x.race_id === 1749 && data.ranking.olympic_bonus > 0) && (
															<div className={ `rank-row rank-row--qualifying rank-row--additional-score` }>
																<div className='date'>{ moment.utc(x.date).format("DD MMM") }</div>
																<a className='race'>
																	Additional score
																	<button className='tooltip-button' popovertarget="additional-score-tooltip">
																		<img src='../../../assets/icons/info-circle-white.svg'
																			alt='"I" character surrounded by circle' />
																	</button>

																	<div className='tooltip hidden' id="additional-score-tooltip" role='tooltip'
																		aria-label="Olympic bonus explainer tooltip">
																		<b>ADDITIONAL SCORE FOR OLYMPIC ATHLETE</b>
																		<p>T100 athletes who raced at the 2024 Olympic Games and are unable to secure 3 T100
																			results prior to the Dubai T100 Triathlon World Championship Final are awarded the
																			average of their Ibiza T100 and Lake Las Vegas T100 point scores as their 3rd race
																			score.</p>
																	</div>
																</a>
																<div className='position'>-</div>
																<div className='time'>-</div>
																<div className='points red'>{ data.ranking.olympic_bonus.toFixed() }</div>
															</div>
														) }
													</Fragment>
												)
											}) }

											{ data.won_championships && (
												<div class='hide-for-desktop'>
													<div className='rank-row head'>
														<div className='winner'>Winner</div>
													</div>

													<div className='rank-row won-championships'>
														{ data.won_championships.map((championship, key) => (
															<T100Logo tier={ championship.name === 'Wildcard' ? championship.name : '' } noAthleteText
																useImage />
														)) }
													</div>
												</div>
											) }
										</div>
										, domDetails)

									const tooltipButtons = document.querySelectorAll('.tooltip-button');

									// Function to hide all tooltips
									function hideAllTooltips() {
										tooltipButtons.forEach((button) => {
											const tooltip = document.getElementById(button.getAttribute('popovertarget'));
											tooltip.classList.add('hidden');
										});
									}

									tooltipButtons.forEach((button) => {
										const tooltip = document.getElementById(button.getAttribute('popovertarget'));
										tooltip.classList.add('hidden');

										button.addEventListener('click', (event) => {
											event.stopPropagation();
											const tooltipIsOpen = !tooltip.classList.contains('hidden')

											if (tooltipIsOpen) {
												hideAllTooltips();
												tooltip.classList.add('hidden');
											} else {
												tooltip.classList.remove('hidden')
											}
										});
									});

									document.addEventListener('click', (event) => {
										// Check if the click is outside any tooltip element
										let clickInsideTooltip = false;
										tooltipButtons.forEach((button) => {
											const tooltip = document.getElementById(button.getAttribute('popovertarget'));
											if (tooltip.contains(event.target)) {
												clickInsideTooltip = true;
											}
										});

										if (!clickInsideTooltip) {
											hideAllTooltips();
										}
									});
								})
						} else {
							fetch("/ranking/" + setid + "/" + id + "/details")
								.then(data => data.json())
								.then(data => {
									let details = JSON.parse(data.ranking.details)
									if (!details) {
										return
									}
									domDetails.__expanded = true

									let subtitle = ''
									if (details[disciplineIndex].type === 'best_sum') {
										subtitle = 'Sum of best' + details[disciplineIndex].best_sum.length
									}
									else if (details[disciplineIndex].type === 'best_avg') {
										subtitle = 'Sum of best' + (details[disciplineIndex].best_sum?.length ? details[disciplineIndex].best_sum.length : " 3")
									}
									else if (['best_avg_07_22', 'best_avg_08_22'].includes(details[disciplineIndex].type)) {
										subtitle = disciplineIndex === 3
											? 'Average of 3 best eligible results'
											: 'Average of 2 best eligible results'
									}
									else if (details[disciplineIndex].type === '2023_last_52w') {
										subtitle = 'Average of 3 best eligible results'
									}
									else if (details[disciplineIndex].type === '2021_pre_cc') {
										subtitle = details[disciplineIndex].results.length === 2
											? 'Average Best 2'
											: ''
									}
									let show2022Explainer = false

									if (details[disciplineIndex].type == 'best_sum') {
										render(
											<div className='rank-details' key={ 'details_' + id }>
												<div className='rank-row title'>
													<div>Scoring Breakdown</div><div className='red px-2'>{ subtitle }</div>
												</div>
												<div className='rank-row head'>
													<div className='date'>Date</div>
													<div className='race'>Race</div>
													<div className='time'>Time</div>
													<div className='time'>AIT</div>
													<div className='points'>Points</div>
												</div>
												{ details[disciplineIndex].best_sum.map(x => {
													let alias
													const isRaceObject = x.race !== undefined
													const isSeriesId = isRaceObject ? x.race?.series_id !== undefined : x.series_id !== undefined

													if (isRaceObject) {
														alias = isSeriesId ? series.filter(s => s.id === x.race.series_id)[0].alias : (series.filter(s => s.name == x.race.serie)[0]?.alias || x.race.alias)
													} else {
														alias = isSeriesId ? series.filter(s => s.id === x.series_id)[0].alias : (series.filter(s => s.name == x.serie)[0]?.alias || x.alias)
													}

													return (
														<div className='rank-row'>
															<div className='date'>{ moment.utc(x.date).format("DD MMM YY") }</div>
															<a className='race' href={ '/race/' + (alias) + '/' + x.year + '/results' }>{ x.name }</a>
															<div className='time'>{ secondsToTime(x[disciplineName]) }</div>
															<div className='time'>{ x["ait" + (discipline ? discipline + "_" : "") + "_" + division] }</div>
															<div className='points red'>{ x[disciplineName + "_points"].toFixed(2) }</div>
														</div>
													)
												}) }
											</div>
											, domDetails)
									}
									else if (details[disciplineIndex].type == '2021_pre_cc') {
										render(
											<div className='rank-details' key={ 'details_' + id }>
												<div className='rank-row title'>
													<div>Scoring Breakdown</div><div className='red px-2'>{ subtitle }</div>
												</div>
												<div className='rank-row head'>
													<div className='date'>Date</div>
													<div className='race'>Race</div>
													<div className='time'>Time</div>
													<div className='time'>AIT</div>
													<div className='points'>Points</div>
												</div>
												{ details[disciplineIndex].results.map(y => {
													if (y.type == "race") {
														let alias
														let x = y.race
														const isRaceObject = x.race !== undefined
														const isSeriesId = isRaceObject ? x.race?.series_id !== undefined : x.series_id !== undefined

														if (isRaceObject) {
															alias = isSeriesId ? series.filter(s => s.id === x.race.series_id)[0].alias : (series.filter(s => s.name == x.race.serie)[0]?.alias || x.race.alias)
														} else {
															alias = isSeriesId ? series.filter(s => s.id === x.series_id)[0].alias : (series.filter(s => s.name == x.serie)[0]?.alias || x.alias)
														}

														return (
															<div className='rank-row'>
																<div className='date'>{ x.date && moment.utc(x.date).format("DD MMM YY") }</div>
																<a className='race' href={ '/race/' + alias + '/' + x.year + '/results' }>{ x.name }</a>
																<div className='time'>{ secondsToTime(x[disciplineName]) }</div>
																<div className='time'>{ x["ait" + (discipline ? discipline + "_" : "") + "_" + division] }</div>
																<div className='points red'>{ y.score.toFixed(2) }</div>
															</div>
														)
													}
													else if (y.type == "bestavg") {
														return (<div className='group'>
															{ y.results.map(x => {
																let alias
																const isRaceObject = x.race !== undefined
																const isSeriesId = isRaceObject ? x.race?.series_id !== undefined : x.series_id !== undefined

																if (isRaceObject) {
																	alias = isSeriesId ? series.filter(s => s.id === x.race.series_id)[0].alias : (series.filter(s => s.name == x.race.serie)[0]?.alias || x.race.alias)
																} else {
																	alias = isSeriesId ? series.filter(s => s.id === x.series_id)[0].alias : (series.filter(s => s.name == x.serie)[0]?.alias || x.alias)
																}

																return (
																	<div className='rank-row text-grey'>
																		<div className='date'>{ moment.utc(x.date).format("DD MMM YY") }</div>
																		<a className='race text-grey' href={ '/race/' + alias + '/' + x.year + '/results' }>{ x.name }</a>
																		<div className='time'>{ secondsToTime(x[disciplineName]) }</div>
																		<div className='time'>{ x["ait" + (discipline ? discipline + "_" : "") + "_" + division] }</div>
																		<div className='points'>{ x[disciplineName + "_points"].toFixed(2) }</div>
																	</div>
																)
															}) }
															<div className='rank-row'>
																<div className='date'></div>
																<a className='race'>Average Best { y.results.length } 2019/2020 seasons</a>
																<div className='time'></div>
																<div className='time'></div>
																<div className='points red'>{ y.score.toFixed(2) }</div>
															</div>
														</div>)
													}
												}) }
											</div>
											, domDetails)
									}
									else {
										render(
											<div className='rank-details' key={ 'details_' + id }>
												<div className='rank-row title'>
													<div>Scoring Breakdown</div><div className='red px-2'>{ subtitle }</div>
												</div>
												<div className='rank-row head'>
													<div className='date'>Date</div>
													<div className='race'>Race</div>
													<div className='time'>Time</div>
													<div className='time'>{ data.ranking.ranking_system_id === 2
														? 'Place'
														: 'AIT' }</div>
													<div className='points'>Points</div>
												</div>
												{ details[disciplineIndex].results.map(x => {
													let alias
													let points = x.race[disciplineName + "_points"].toFixed(2)
													const isRaceObject = x.race !== undefined
													const isSeriesId = isRaceObject ? x.race?.series_id !== undefined : x.series_id !== undefined

													if (isRaceObject) {
														alias = isSeriesId ? series.filter(s => s.id === x.race.series_id)[0].alias : (series.filter(s => s.name == x.race.serie)[0]?.alias || x.race.alias)
													} else {
														alias = isSeriesId ? series.filter(s => s.id === x.series_id)[0].alias : (series.filter(s => s.name == x.serie)[0]?.alias || x.alias)
													}

													if (data.ranking.ranking_system_id === 2 && x.race['year'] === 2022 && x.race['ranking_system_id'] === 2) {
														points += '*'
														show2022Explainer = true
													}
													return (
														<div className='rank-row'>
															<div className='date'>{ moment.utc(x.race.date).format("DD MMM YY") }</div>
															<a className='race' href={ '/race/' + alias + '/' + x.race.year + '/results' }>{ x.race.name + (x.adjust ? ` (${x.adjust})` : '') }</a>
															<div className='time'>{ secondsToTime(x.race[disciplineName]) }</div>
															<div className='time'>{ data.ranking.ranking_system_id === 2
																? disciplineName === 'overall'
																	? x.race['rank']
																	: x.race[disciplineName + '_rank']
																: x.race["ait" + (discipline ? discipline + "_" : "") + "_" + division] }</div>
															<div className='points red'>{ points }</div>
														</div>
													)
												}) }
												{ show2022Explainer && <p className='explainer--2022-recalculated mt-3'>* Recalculated values from 2022 based on new ranking system.</p> }
											</div>
											, domDetails)
									}
								})
						}
						domDetails.classList.add('show')
					})
			}
			else {
				domDetails.__expanded = false
				domDetails.classList.remove('show')
			}

		})
	}
}

let expandBreakdowns = document.getElementsByClassName("expand-breakdown")
if (expandBreakdowns) {
	for (let expand of expandBreakdowns) {
		expand.addEventListener("click", function (e) {
			const breakdown = e.currentTarget.parentNode.parentNode.nextSibling.querySelector(".points-breakdown")
			if (!breakdown.__expanded) {
				breakdown.__expanded = true
				breakdown.classList.add('show')
			} else {
				breakdown.__expanded = false
				breakdown.classList.remove('show')
			}
		})
	}
}

let currentStandingButtons = document.querySelectorAll(".t100-home-page__current-standings button")
if (currentStandingButtons) {
	for (let button of currentStandingButtons) {
		const card = button.nextSibling
		card.__expanded = false
		card.style.display = 'none';
		button.addEventListener("click", function (e) {
			const card = e.currentTarget.nextSibling
			if (!card.classList.contains("show")) {
				for (let button of currentStandingButtons) {
					const card = button.nextSibling;
					card.__expanded = false
					card.classList.remove('show')
				}

				card.__expanded = true
				card.style.display = 'grid';
				setTimeout(() => {
					card.classList.add('show')
				}, 1);
			} else {
				card.__expanded = false
				setTimeout(() => {
					card.style.display = 'none';
				}, 350);
				card.classList.remove('show')
			}
		})
	}
}

window.addEventListener("click", (e) => {
	let clickedInsideSlide = false;

	currentStandingButtons.forEach(slide => {
		if (slide.contains(e.target)) {
			clickedInsideSlide = true;
		}
	});

	if (!clickedInsideSlide && !e.target.closest(".swiper-slide")) {
		for (let button of currentStandingButtons) {
			const card = button.nextSibling
			card.__expanded = false
			card.classList.remove('show')
			setTimeout(() => {
				card.style.display = 'none';
			}, 350);
		}
	}
})
